(function($) {
    var download = function () {
        this.ios_btn = $('#ios-d');
        this.android_btn = $('#android-d');
        this.iosDownloadUrl = 'https://itunes.apple.com/us/app/huo-xing-zhi-bo/id1094268176?ls=1&mt=8';
        this.anDownloadUrl = ' http://a.app.qq.com/o/simple.jsp?pkgname=com.xiaochang.easylive&ckey=CK1384460052749';
    }
    
    download.prototype.init = function () {
        var ua = this.ua = navigator.userAgent.toLowerCase(), srcFrom = commonObj.utils.getQueryStrFn('from');
        this.isQQorWeChat = /micromessenger/.test(ua) || /qq\//.test(ua) || /weibo/.test(ua);
        this.isAndroidCB = commonObj.browser.isAndroidFn() && /changba/.test(ua);
        if (/(Android)/i.test(ua) && srcFrom !== '') {
            if (!this.isQQorWeChat) { 
                this.anDownloadUrl = 'https://mars.changba.com/lastestapp.php?from=' + srcFrom;
            }
        } else {
            if (this.isQQorWeChat) {
                this.anDownloadUrl = " http://a.app.qq.com/o/simple.jsp?pkgname=com.xiaochang.easylive&ckey=CK1384460052749";
            } else {
                this.anDownloadUrl = "https://mars.changba.com/lastestapp.php?from=marsofficial";
            }
        }

        if (/(iPhone|iPad|iPod|iOS)/i.test(ua)){
            if (this.isQQorWeChat) {
                this.iosDownloadUrl = " http://a.app.qq.com/o/simple.jsp?pkgname=com.xiaochang.easylive&ckey=CK1384460052749";
            } else {
                this.iosDownloadUrl = "https://itunes.apple.com/us/app/huo-xing-zhi-bo/id1094268176?ls=1&mt=8";
            }
        }

        this.bindEvents();
    };
    
    download.prototype.bindEvents = function () {
        var self = this;
        self.ios_btn.on('click', function() {
            commonObj.utils.loadScript("http://s4.cnzz.com/stat.php?id=1258963915&web_id=1258963915");
            window.location.href = self.iosDownloadUrl;
        });

        self.android_btn.on('click', function() {
            commonObj.utils.loadScript("http://s95.cnzz.com/stat.php?id=1258963997&web_id=1258963997");
            window.location.href = self.anDownloadUrl;
        });

    };

    new download().init();
    
})(Zepto)
